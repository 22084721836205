export default [
  {
    url: require('@/assets/img/auth/login/loginQuoteImage01.webp')
  },
  {
    url: require('@/assets/img/auth/login/loginQuoteImage02.webp')
  },
  {
    url: require('@/assets/img/auth/login/loginQuoteImage03.webp')
  },
  {
    url: require('@/assets/img/auth/login/loginQuoteImage04.webp')
  },
  {
    url: require('@/assets/img/auth/login/loginQuoteImage05.webp')
  },
  {
    url: require('@/assets/img/auth/login/loginQuoteImage06.webp')
  },
  {
    url: require('@/assets/img/auth/login/loginQuoteImage07.webp')
  }
]
