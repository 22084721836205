<template>
  <div class="grid-content position-relative ib-auth-login-image-wrapper">
    <!-- Logo -->
    <a
      class="logo position-absolute"
      href="https://ideabuddy.com"
      target="_blank"
    >
      <img src="@/assets/img/logos/ideaBuddy-color.svg">
    </a>
    <!-- /Logo -->

    <div
      class="d-flex flex-column align-items-center justify-content-center"
    >
      <div class="image-text">
        <div class="logInImageWrapper">
          <img :src="quoteImages.url" alt="signup image">
        </div>

        <div class="d-flex flex-column align-items-center text-wrapper">
          <p class="m-0 font-outfit-semi-bold quote-text" v-html="quote.quote" />
          <p class="m-0 mt-2 quote-author">
            {{ quote.author }}
          </p>
        </div>

      </div>
    </div>
  </div>
</template>
<script>

import loginImages from '@/data/loginImages'

export default {
  name: 'AuthLoginImage',

  props: {
    image: {
      type: String,
      required: true
    }
  },

  computed: {
    quoteImages () {
      const totalImages = loginImages.length
      const imageNumber = Math.floor(Math.random() * totalImages)

      return loginImages[imageNumber]
    },
    quote () {
      const totalQuotes = this.$t('auth.logIn.quotes').length
      const quoteNumber = Math.floor(Math.random() * totalQuotes)

      return this.$t('auth.logIn.quotes')[quoteNumber]
    }
  }
}
</script>

<style scoped lang="scss">

.ib-auth-login-image-wrapper {
  background: #ffffff;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10%;

  .logo {
    top: 3%;
    left: 3%;
  }

  .image-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
    font-size: 20px;
    text-align: center;
    line-height: 22px;

    img {
      width: 100%;
    }

    .logInImageWrapper {}

    .text-wrapper {
      line-height: 32.4px;
      max-width: 450px;

      .quote-text {
        font-size: 20px;
        max-width: 560px;
      }
      .quote-author {
        text-transform: uppercase;
        font-size: 16px;
      }
    }
  }
}
</style>
